<script lang="ts" setup>
import type { HintedString } from 'core'

const { variant = 'ghost', size = 'base', ...props } = defineProps<{
  variant?: HintedString<'primary' | 'secondary' | 'ghost'>
  size?: HintedString<'base' | 'sm' | 'lg'>

  /** CSS icon. Extra classes can be assigned as well */
  icon?: string

  loading?: boolean
  disabled?: boolean

  /** Resolves a promise callback and shows loader */
  onResolveClick?: () => any | Promise<any>
}>()

const isResolving = ref<boolean>(false)

async function onClick() {
  const fn = props.onResolveClick
  if (!fn)
    return

  isResolving.value = true
  await fn()
  isResolving.value = false
}

defineExpose({
  click: onClick
})
</script>

<template>
  <button
    type="button"
    class="cta"
    :class="[`${variant}-variant`, `${size}-size`]"
    :disabled="disabled || loading || isResolving"
    @click="onClick"
  >
    <Spinner v-if="loading || isResolving" size="xs" />
    <span v-else-if="icon" :class="icon" />
    <slot v-else />
  </button>
</template>

<style scoped lang="sass">
.cta
  --size: 2rem
  --icon-size: 1rem

  @apply size-$size text-size-$icon-size flex-center flex-shrink-0 rounded-full
  @apply disabled:pointer-events-none cursor-pointer transform-gpu active:scale-97
  @apply outline-1 outline-solid outline-transparent outline-offset-2px

  transition: all 150ms ease
  transition-property: color, background-color, border-color, outline-color, transform

// Sizes
.cta
  &.lg-size
    @apply $size-3rem $icon-size-1.25rem outline-2 outline-offset-4

  &.base-size
    @apply $size-2rem $icon-size-1rem outline-offset-3

  &.sm-size
    @apply $size-1.5rem $icon-size-0.75rem

// Variants
.cta
  &.primary-variant
    @apply $primary-[$color-primary,@theme-colors-primary-500]
    @apply bg-$primary $text-[$color-text-on-primary,@theme-colors-neutral-200]
    @apply $focus-outline-color-$primary

  &.secondary-variant, &.ghost-variant
    @apply $neutral-[$color-neutral,@theme-colors-neutral-500]
    --neutral-20: hsl(from var(--color-neutral) h s l / 0.2)
    --neutral-10: hsl(from var(--color-neutral) h s l / 0.1)

    @apply hover:bg-$neutral-10 active:bg-$neutral-20 disabled:bg-$neutral-20

  &.secondary-variant
    @apply $bg-[$color-background-elevated,@theme-colors-shade-light]
    @apply $text-$neutral $focus-outline-color-$neutral

  &.ghost-variant
    @apply $neutral-[$color-neutral,@theme-colors-neutral-400]
    @apply $bg-transparent $text-$neutral $focus-outline-color-$neutral

    // Size overrides
    &.base-size
      @apply $size-1.5rem $icon-size-0.875rem

    &.sm-size
      @apply $size-1.25rem $icon-size-0.625rem

  @apply bg-$bg text-$text
  @apply focus-within-outline-$focus-outline-color active-outline-$focus-outline-color
  @apply disabled:grayscale-80 disabled:opacity-40

//;
</style>
